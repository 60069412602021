<template>
  <div class="more">
    <van-nav-bar
      :title="$t('app.center')"
      left-arrow
      @click-left="$router.go(-1)"
      :placeholder="true"
      fixed
    />
    <div class="more-content">
      <van-cell-group class="more-profile">
        <van-cell :title="$t('Menu.profile')" :border="false" />
        <van-grid :column-num="3" :border="false">
          <van-grid-item
            :icon="newMenu.personal_information.account"
            :text="$t('Menu.accountInfo')"
            to="/member/profile"
          />
          <van-grid-item
            :icon="newMenu.personal_information.bank"
            :text="$t('Menu.bankInfo')"
            to="/funds/bankinfo"
          />
          <van-grid-item
            :icon="newMenu.personal_information.card"
            :text="$t('Menu.idInfo')"
            to="/member/address"
          />
        </van-grid>
      </van-cell-group>
      <van-cell-group class="more-accountManagent">
        <van-cell :title="$t('Menu.accountManagent')" :border="false" />
        <van-grid :column-num="3" :border="false">
          <van-grid-item
            :icon="newMenu.account_management.login_pwd"
            :text="$t('Menu.updateCrmPwd')"
            to="/member/crmpwd"
          />
          <van-grid-item
            :icon="newMenu.account_management.trade_pwd"
            :text="$t('Menu.updateMt4Pwd')"
            to="/member/mt4pwd"
          />
          <van-grid-item
            :icon="newMenu.account_management.adjustment_lerve"
            :text="$t('Menu.changeLeverage')"
            to="/member/leverage"
          />
        </van-grid>
      </van-cell-group>
      <van-cell-group class="more-fundsManagent">
        <van-cell :title="$t('Menu.fundsManagent')" :border="false" />
        <van-grid :column-num="4" :border="false">
          <van-grid-item
            :icon="newMenu.enter_out.withdrawal"
            :text="$t('Menu.deposit')"
            to="/funds/deposit"
          />
          <van-grid-item
            :icon="newMenu.enter_out.deposit"
            :text="$t('Menu.withdrawals')"
            to="/funds/withdrawals"
          />
          <van-grid-item
            :icon="newMenu.enter_out.transfer"
            :text="$t('Menu.transfer')"
            to="/funds/transfer"
          />
          <van-grid-item
            :icon="newMenu.enter_out.account_ls"
            :text="$t('Menu.fundsHistory')"
            to="/funds/history"
          />
        </van-grid>
      </van-cell-group>
      <van-cell-group class="more-community" v-show="showSingal">
        <van-cell :title="$t('Menu.community')" :border="false" />
        <van-grid :column-num="3" :border="false">
          <van-grid-item
            :icon="newMenu.documentary_community.singal"
            :text="$t('Menu.signal')"
            to="/community"
          />
          <van-grid-item
            :icon="newMenu.documentary_community.neigborhood"
            :text="$t('mycopy')"
            to="/signal/mycopy"
          />
          <van-grid-item
            :icon="newMenu.documentary_community.me_singal"
            :text="$t('Menu.mySignal')"
            to="/signal/mysignal"
          />
        </van-grid>
      </van-cell-group>
      <van-cell-group class="more-reportCenter">
        <van-cell :title="$t('Menu.reportCenter')" :border="false" />
        <van-grid :column-num="3" :border="false">
          <van-grid-item
            :icon="newMenu.report_center.pc"
            :text="$t('Menu.closeReport')"
            to="/report/ordershistory"
          />
          <van-grid-item
            :icon="newMenu.report_center.cc"
            :text="$t('Menu.positionReport')"
            to="/report/position"
          />
          <van-grid-item
            :icon="newMenu.report_center.yj"
            :text="$t('Menu.performance')"
            to="/report/business"
          />
          <van-grid-item
            :icon="newMenu.report_center.xy"
            :text="$t('Menu.creditReport')"
            to="/report/credit"
          />
          <van-grid-item
            :icon="newMenu.report_center.yjj"
            :text="$t('Menu.commissionReport')"
            to="/report/commission"
            :style="{
              display: userinfo.haveIbLogin == true ? 'block' : 'none',
            }"
          />
          <van-grid-item
            :icon="newMenu.report_center.yj_tj"
            :text="$t('Menu.commissionTotal')"
            to="/report/bonus"
            :style="{
              display: userinfo.haveIbLogin == true ? 'block' : 'none',
            }"
          />
          <van-grid-item
            :icon="newMenu.report_center.hz"
            :text="$t('Menu.summaryReport')"
            to="/report/summary"
          />
        </van-grid>
      </van-cell-group>
    </div>

    <!-- <div class="more-content">
      <van-cell-group class="more-profile">
        <van-cell :title="$t('Menu.profile')" :border="false" />
        <van-grid :column-num="3" :border="false">
          <van-grid-item
            icon="user-circle-o"
            :text="$t('Menu.accountInfo')"
            to="/member/profile"
          />
          <van-grid-item
            icon="paid"
            :text="$t('Menu.bankInfo')"
            to="/funds/bankinfo"
          />
          <van-grid-item
            icon="idcard"
            :text="$t('Menu.idInfo')"
            to="/member/address"
          />
        </van-grid>
      </van-cell-group>
      <van-cell-group class="more-accountManagent">
        <van-cell :title="$t('Menu.accountManagent')" :border="false" />
        <van-grid :column-num="3" :border="false">
          <van-grid-item
            :icon="menu.shield"
            :text="$t('Menu.updateCrmPwd')"
            to="/member/crmpwd"
          />
          <van-grid-item
            :icon="menu.security"
            :text="$t('Menu.updateMt4Pwd')"
            to="/member/mt4pwd"
          />
          <van-grid-item
            :icon="menu.leverage"
            :text="$t('Menu.changeLeverage')"
            to="/member/leverage"
          />
        </van-grid>
      </van-cell-group>
      <van-cell-group class="more-fundsManagent">
        <van-cell :title="$t('Menu.fundsManagent')" :border="false" />
        <van-grid :column-num="4" :border="false">
          <van-grid-item
            icon="balance-pay"
            :text="$t('Menu.deposit')"
            to="/funds/deposit"
          />
          <van-grid-item
            icon="balance-o"
            :text="$t('Menu.withdrawals')"
            to="/funds/withdrawals"
          />
          <van-grid-item
            icon="refund-o"
            :text="$t('Menu.transfer')"
            to="/funds/transfer"
          />
          <van-grid-item
            icon="balance-list-o"
            :text="$t('Menu.fundsHistory')"
            to="/funds/history"
          />
        </van-grid>
      </van-cell-group>
      <van-cell-group class="more-community">
        <van-cell :title="$t('Menu.community')" :border="false" />
        <van-grid :column-num="3" :border="false">
          <van-grid-item
            icon="award-o"
            :text="$t('Menu.signal')"
            to="/community"
          />
          <van-grid-item
            icon="flag-o"
            :text="$t('Menu.myCopy')"
            to="/signal/mycopy"
          />
          <van-grid-item
            icon="medal-o"
            :text="$t('Menu.mySignal')"
            to="/signal/mysignal"
          />
        </van-grid>
      </van-cell-group>
      <van-cell-group class="more-reportCenter">
        <van-cell :title="$t('Menu.reportCenter')" :border="false" />
        <van-grid :column-num="3" :border="false">
          <van-grid-item
            icon="description"
            :text="$t('Menu.closeReport')"
            to="/report/ordershistory"
          />
          <van-grid-item
            icon="records"
            :text="$t('Menu.positionReport')"
            to="/report/position"
          />
          <van-grid-item
            icon="after-sale"
            :text="$t('Menu.depositReport')"
            to="/report/business"
          />
          <van-grid-item
            icon="certificate"
            :text="$t('Menu.creditReport')"
            to="/report/credit"
          />
          <van-grid-item
            :icon="menu.yj"
            :text="$t('Menu.commissionReport')"
            to="/report/commission"
            :style="{
              display: userinfo.haveIbLogin == true ? 'block' : 'none',
            }"
          />
          <van-grid-item
            :icon="menu.tj"
            :text="$t('Menu.commissionTotal')"
            to="/report/bonus"
            :style="{
              display: userinfo.haveIbLogin == true ? 'block' : 'none',
            }"
          />
          <van-grid-item
            :icon="menu.hz"
            :text="$t('Menu.summaryReport')"
            to="/report/summary"
          />
        </van-grid>
      </van-cell-group>
    </div> -->
  </div>
</template>

<script>
import baseConfig from '@/utils/baseConfig'
export default {
  data() {
    return {
      // menu: { 
      //   shield: require("@/assets/image/shield.png"),
      //   security: require("@/assets/image/security.png"),
      //   leverage: require("@/assets/image/leverage.png"),
      //   leverage: require("@/assets/image/leverage.png"),
      //   hz: require("@/assets/image/crm/hz.svg"),
      //   yj: require("@/assets/image/crm/yj.svg"),
      //   tj: require("@/assets/image/crm/tj.svg"),
      // },
      showSingal: baseConfig.showSingal,
      newMenu: {
        personal_information: {
          account: require("@/assets/image/new_crm/account_information.png"),
          bank: require("@/assets/image/new_crm/bank_information.png"),
          card: require("@/assets/image/new_crm/certificate_information.png"),
        },
        account_management: {
          trade_pwd: require("@/assets/image/new_crm/transaction_password.png"),
          login_pwd: require("@/assets/image/new_crm/CRM_password.png"),
          adjustment_lerve: require("@/assets/image/new_crm/change_lervr.png"),
        },
        enter_out: {
          withdrawal: require("@/assets/image/crm/deposit.png"),
          deposit: require("@/assets/image/crm/withdrawal.png"),
          transfer: require("@/assets/image/crm/transfer.png"),
          account_ls: require("@/assets/image/crm/statement.png"),
        },
        documentary_community: {
          singal: require("@/assets/image/new_crm/signal.png"),
          neigborhood: require("@/assets/image/new_crm/documentary_community.png"),
          me_singal: require("@/assets/image/new_crm/me_signal.png"),
        },
        report_center: {
          pc: require("@/assets/svg/pc.svg"),
          cc: require("@/assets/image/crm/positions.png"),
          yj: require("@/assets/svg/yj_report.svg"),
          xy: require("@/assets/svg/xy_report.svg"),
          yjj: require("@/assets/svg/yjj_report.svg"),
          yj_tj: require("@/assets/svg/yongjintongji.svg"),
          hz: require("@/assets/svg/hz_report.svg"),
        },
      },
      userinfo: this.$store.state.userinfo,
    };
  },
};
</script>

<style scoped>
.more {
  background-color: #f7f8fa;
}
.van-cell {
  font-size: 15px;
  font-weight: 600;
}
.van-cell-group {
  margin-top: 8px;
  border-radius: 8px;
  overflow: hidden;
}
.more-content {
  padding: 0 10px 60px;
  background-color: #f7f8fa;
}
.more-accountManagent .van-grid {
  flex-wrap: nowrap;
}
.more-accountManagent >>> .van-grid-item {
  width: 33.33333%;
}
.more-fundsManagent >>> .van-grid-item {
  width: 25%;
}
.more-community >>> .van-grid-item {
  width: 33.3333%;
}
.more-reportCenter >>> .van-grid-item {
  width: 33.3333%;
}
.van-grid >>> .van-grid-item__text {
  white-space: nowrap;
}
.more-profile .van-grid {
  display: flex;
  flex-wrap: nowrap;
}
</style>